import React from "react"
import styles from "./portrait.module.scss"

export default function Portrait(props) {
  return (
    <div className={styles.container}>
      <div className={styles.portraitContainer}>
        <div className={styles.portrait}></div>
        <div className={styles.circleOverlay1}></div>
        <div className={styles.circleOverlay2}></div>
      </div>
  </div>
  )
}