import React from "react"
import styles from "./logo-garden.module.scss"

const LogoGarden = (props) => (
    <div className={styles.logoGarden}>
        <div className={styles.logo}></div>
        <div className={styles.logo}></div>
        <div className={styles.logo}></div>
        <div className={styles.logo}></div>
        <div className={styles.logo}></div>
        <div className={styles.logo}></div>
        <div className={styles.logo}></div>
        <div className={styles.logo}></div>
        <div className={styles.logo}></div>
    </div>
)

export default LogoGarden