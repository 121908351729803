import React from "react"
// import Layout from "../components/layout/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Portrait from "../components/portrait/portrait"
import styles from "../styles/home.module.scss"
import ScrollDownArrow from "../components/scroll-down-arrow/scroll-down-arrow"
import GridNav from "../components/grid-nav/grid-nav"
import LogoGarden from "../components/logo-garden/logo-garden"

const IndexPage = () => (
  <div className={styles.bg}>
    {/* <Layout className={styles.home}> */}
      <SEO title="Home" />
      <section className={styles.home}>
        <div className={styles.introHeightContainer}>
          <div className={styles.spacer}></div>
          <div className={styles.introContainer}>
            <div className={styles.blurb}
                  data-sal="fade-in"
                  data-sal-delay="200"
                  data-sal-easing="ease"
                  data-sal-duration="1000"
              >  
              <h4>Digital Product Designer</h4>
              <h1>Bridging the gap between design and development.</h1>
              <p>
                Hi, I’m Antonio! I explore the intersection between design and technology. I'm
                a digital product designer, designing the next generation of digital experiences.
              </p>
              <div className={styles.scrollDown}>
                <ScrollDownArrow url="/#grid" />
              </div>
            </div>
            <div className={styles.portrait}
              data-sal="zoom-in"
              data-sal-delay="200"
              data-sal-easing="ease"
              data-sal-duration="1000"
              >
              <Portrait />
            </div>
          </div>
          <div
            data-sal="fade-in"
            data-sal-delay="600"
            data-sal-easing="ease"
            data-sal-duration="1400"
          >
            <LogoGarden />
          </div>
        </div>
        <div id="grid" className={styles.gridContainer}
          data-sal="fade-in"
          // data-sal-delay="200"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          <GridNav/>
        </div>
      </section>
    {/* </Layout> */}
  </div>
)

export default IndexPage
