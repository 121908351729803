import React from "react"
import Link from "gatsby-plugin-transition-link/AniLink"
import styles from "./grid-nav.module.scss"
import classnames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"

const GridNav = props => (
  <div className={styles.container}>
    <div className={styles.grid}>
      <Link paintDrip to="/about/" duration={1} hex="#000"
            className={classnames(styles.tile, styles.about)}>
        <div>
          <FontAwesomeIcon icon={faExternalLinkAlt} />
          {/* <span>001</span> */}
        </div>
        <div className={styles.text}>
          {/* <span>001</span> */}
          <h1>About</h1>
          <p>
            My style is influenced by skate, music, and street culture. Video
            games play a big part for my love of storytelling, usability and
            interactive experiences.
          </p>
        </div>
      </Link>
      <Link paintDrip to="/work/" duration={1} hex="#000"
            className={classnames(styles.tile, styles.work)}>
        <div>
          <FontAwesomeIcon icon={faExternalLinkAlt} />
          {/* <span>002</span> */}
        </div>
        <div>
          {/* <span>002</span> */}
          <h1>Work</h1>
          <p>
            My current work focuses on designing and developing responsive
            website experiences. I focus on user experience, user
            interface design, and interaction design.
          </p>
        </div>
      </Link>
      <Link paintDrip to="/resources/" duration={1} hex="#000"
            className={classnames(styles.tile, styles.resources)}>
        <div>
          <FontAwesomeIcon icon={faExternalLinkAlt} />
          {/* <span>003</span> */}
        </div>
        <div>
          {/* <span>003</span> */}
          <h1>Resources</h1>
          <p>
            My essentials, a reference guide, the holy grail. Find
            resources that I use to get work done when it comes to design and
            development, plus other goodies.
          </p>
        </div>
      </Link>
      <Link paintDrip to="/resume/" duration={1} hex="#000"
            className={classnames(styles.tile, styles.resume)}>
        <div>
          <FontAwesomeIcon icon={faExternalLinkAlt} />
          {/* <span>004</span> */}
        </div>
        <div>
          {/* <span>004</span> */}
          <h1>Resume</h1>
          <p>
            I studied computer science with an emphasis towards game design at
            UC Santa Cruz (go slugs). I'm currently working at Oscar, helping
            to design and build the next generation of digital experiences.
          </p>
        </div>
      </Link>
    </div>
  </div>
)

export default GridNav
